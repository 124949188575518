<template>
    <div class="custom-control custom-radio">
        <input
            type="radio"
            class="custom-control-input"
            v-bind="$props"
            @input="onInput"
            :checked="model === value"
        >
        <label
            class="custom-control-label"
            :for="id"
        >{{ label }}</label>
    </div>
</template>

<script>
export default {
    props: ['id', 'name', 'model', 'value', 'label'],

    model: {
        prop: 'model',
        event: 'input',
    },

    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
