<template>
    <div class="form-group">
        <label :class="[{
            'text-danger': form.errors.has(name)
        }]" v-if="label">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>

        <select
            :class="['form-control', {
                'is-invalid': form.errors.has(name)
            }]"
            @input="$emit('input', $event.target.value)"
            v-bind="$props">
            <option v-for="state of orderedStates" :key="state.uf" :value="state.uf">
                {{ state.name }} ({{ state.uf }})
            </option>
        </select>

        <p class="invalid-feedback d-block" v-if="form.errors.has(name)">
            {{ form.errors.get(name) }}
        </p>
    </div>
</template>

<script>
    import { Form } from "@/utils";
    export default {
        props: {
            label: { default: "Estado" },
            value: { default: null },
            name: { default: null },
            form: { default: () => new Form(), type: Form },
            required: { default: false, type: Boolean },
            trackBy: { default: 'uf', type: String },
            options: {
                default: () => [
                    { code: 11, name: 'Rondônia', uf: 'RO'},
                    { code: 12, name: 'Acre', uf: 'AC'},
                    { code: 13, name: 'Amazonas', uf: 'AM'},
                    { code: 14, name: 'Roraima', uf: 'RR'},
                    { code: 15, name: 'Pará', uf: 'PA'},
                    { code: 16, name: 'Amapá', uf: 'AP'},
                    { code: 17, name: 'Tocantins', uf: 'TO'},
                    { code: 21, name: 'Maranhão', uf: 'MA'},
                    { code: 22, name: 'Piauí', uf: 'PI'},
                    { code: 23, name: 'Ceará', uf: 'CE'},
                    { code: 24, name: 'Rio Grande do Norte', uf: 'RN'},
                    { code: 25, name: 'Paraíba', uf: 'PB'},
                    { code: 26, name: 'Pernambuco', uf: 'PE'},
                    { code: 27, name: 'Alagoas', uf: 'AL'},
                    { code: 28, name: 'Sergipe', uf: 'SE'},
                    { code: 29, name: 'Bahia', uf: 'BA'},
                    { code: 31, name: 'Minas Gerais', uf: 'MG'},
                    { code: 32, name: 'Espírito Santo', uf: 'ES'},
                    { code: 33, name: 'Rio de Janeiro', uf: 'RJ'},
                    { code: 35, name: 'São Paulo', uf: 'SP'},
                    { code: 41, name: 'Paraná', uf: 'PR'},
                    { code: 42, name: 'Santa Catarina', uf: 'SC'},
                    { code: 43, name: 'Rio Grande do Sul (*)', uf: 'RS'},
                    { code: 50, name: 'Mato Grosso do Sul', uf: 'MS'},
                    { code: 51, name: 'Mato Grosso', uf: 'MT'},
                    { code: 52, name: 'Goiás', uf: 'GO'},
                    { code: 53, name: 'Distrito Federal', uf: 'DF'},
                ],
                type: Array
            }
        },

        computed: {
            orderedStates() {
                return this.options.sort((a, b) => a.uf < b.uf ? -1 : 1);
            }
        },

        watch: {
            value() {
                this.form.errors.clear(this.name);
            }
        }
    };
</script>
