<template>
    <div class="container">
        <personal-info class="mt-4" />
        <change-password class="mt-5" />
    </div>
</template>

<script>
import { Form } from '@/utils';
import PersonalInfo from '@/components/users/PersonalInfo';
import ChangePassword from '@/components/users/ChangePassword';

export default {
    components: { PersonalInfo, ChangePassword },
}
</script>

