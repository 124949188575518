<template>
    <span
        role="checkbox"
        tabindex="0"
        aria-checked="false"
        @click="toggle"
        :class="{ 't-bg-primary-500': active }"
        class="t-bg-gray-200 t-relative t-inline-block t-flex-shrink-0 t-h-6 t-w-10 t-border-2 t-border-transparent t-rounded-full t-cursor-pointer t-transition-colors t-ease-in-out t-duration-200 focus:t-outline-none"
    >
        <span
            aria-hidden="true"
            :class="{ 't-translate-x-4': active }"
            class="t-translate-x-0 t-inline-block t-h-5 t-w-5 t-rounded-full t-bg-white t-shadow t-transform t-transition t-ease-in-out t-duration-200"
        ></span>
    </span>
</template>

<script>
    export default {
        props: {
            value: { default: null }
        },

        data() {
            return {
                active: this.value
            };
        },

        watch: {
            value() {
                this.active = this.value;
            }
        },

        methods: {
            toggle() {
                this.active = !this.active;
                this.$emit('input', this.active);
            }
        }
    };
</script>
