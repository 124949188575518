<template>
    <div class="t-flex t-h-screen">
        <div
            class="t-bg-gray-200 t-h-screen t-overflow-y-auto t-shadow-lg t-border-r t-border-gray-400 t-p-6"
            style="width: 500px"
        >
            <h1 class="t-text-2xl t-uppercase t-text-center mt-2">Designer</h1>

            <input-file
                @preview="$set(form, 'logo_url', $event)"
                v-model="form.logo_id"
                :file="form.logo"
                :form="form"
                label="Logo"
            ></input-file>

            <div class="t-p-3 t-bg-white t-border t-border-gray-400 t-rounded mb-3">
                <label class="mb-1">Fundo da Página (opcional)</label>

                <div class="t-flex t-space-x-8 t-items-center mb-2">
                    <input-radio
                        id="image-type"
                        name="bg_type"
                        value="image"
                        v-model="form.background_type"
                        @input="$set(form, 'background_type', $event)"
                        label="Imagem"
                    ></input-radio>

                    <input-radio
                        id="color-type"
                        name="bg_type"
                        value="color"
                        v-model="form.background_type"
                        @input="$set(form, 'background_type', $event)"
                        label="Cor"
                    ></input-radio>

                    <input-radio
                        id="none-type"
                        name="bg_type"
                        v-model="form.background_type"
                        @input="$set(form, 'background_type', $event)"
                        label="Nenhum"
                    ></input-radio>
                </div>

                <input-file
                    v-if="form.background_type === 'image'"
                    style="margin-bottom: 0 !important"
                    @preview="$set(form, 'background_url', $event)"
                    v-model="form.background_id"
                    :file="form.background"
                    :form="form"
                    label="Imagem de Fundo"
                ></input-file>

                <input-color
                    v-if="form.background_type === 'color'"
                    :value="form.background_color || 'white'"
                    @input="$set(form, 'background_color', $event)"
                    label="Cor de fundo"
                ></input-color>

            </div>

            <input-color
                :value="form.primary_color || $primaryColor"
                @input="$set(form, 'primary_color', $event)"
                label="Cor primária"
                class="t-mb-4"
            ></input-color>

            <div class="t-p-4 t-bg-white t-rounded t-border t-border-gray-400">
                <input-editor-basic v-model="form.portal_message"></input-editor-basic>
            </div>

            <div class="t-flex t-mt-5">
                <a
                    :href="route('complaints.portal', { company: company.slug })"
                    class="btn t-flex-1 t-text-lg bg-white"
                    target="_blank"
                >Visualizar Portal</a>

                <button-loading
                    @click="save"
                    :loading="form.submitting"
                    class="btn t-flex-1 t-text-lg t-font-bold btn-success t-ml-3"
                >Salvar</button-loading>
            </div>
        </div>

        <div class="t-flex-1 t-h-screen t-overflow-y-auto">
            <complaint-portal
                :edit-mode="true"
                :company="form"
            ></complaint-portal>
        </div>
    </div>
</template>

<script>
import { Form } from '@/utils';

export default {
    props: {
        company: { required: true, type: Object },
    },

    data() {
        return {
            form: new Form(this.company),
            primaryColor: this.$primaryColor,
        };
    },

    watch: {
        primaryColor() {
            this.$set(this.form, 'primary_color', this.primaryColor);
        },

        'form.background_type': function (type) {
            if (type !== 'image') {
                this.form.background_id = null;
            }
        },
    },

    methods: {
        async save() {
            const { data: company } = await this.form.put(this.route('companies.update-portal', this.form));
            this.$toasted.show('Página salva com sucesso.');
            this.form = new Form(company);
        },
    },
};
</script>
