<template>
    <transition name="mt-modal">
        <div v-if="opened" class="mt-modal-overlay" @click="close">
            <div class="mt-modal" @click.stop="() => null">
                <slot name="header">
                    <div class="mt-modal-header">
                        <div v-if="$slots.title || title" class="mt-modal-title">
                            <slot name="title">{{ title }}</slot>
                        </div>

                        <div v-if="!hideClose" @click="close" class="mt-modal-close">
                            &times;
                        </div>
                    </div>
                </slot>

                <div class="mt-modal-body">
                    <slot></slot>
                </div>

                <div class="mt-modal-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            title: { default: null },
            hideClose: { default: false, type: Boolean }
        },

        data: () => ({
            opened: false
        }),

        methods: {
            open() {
                this.opened = true;
                this.$emit('open');
                setTimeout(() => this.$emit('opened'), 500);
            },

            close() {
                this.opened = false;
                this.$emit('close');
                setTimeout(() => this.$emit('closed'), 500);
            }
        }
    };
</script>

<style lang="scss">
    $modalSpacingY: 3rem;
    $paddingSize: 1.25rem;
    $animationSpeed: 0.3s;
    $modalHeight: calc(-100% - $modalSpacingY);
    $timingFunction: cubic-bezier(0.165, 0.84, 0.44, 1);

    .mt-modal-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow-y: auto;
        z-index: 2000;
        padding: 0 1rem;
        background: rgba(0, 0, 0, 0.5);

        .mt-modal {
            background: white;
            top: 0;
            max-width: 600px;
            margin: $modalSpacingY auto;
            overflow-y: auto;
            border-radius: 0.5rem;
            position: relative;

            .mt-modal-close {
                display: flex;
                position: absolute;
                top: $paddingSize;
                right: $paddingSize;
                font-size: 24px;
                cursor: pointer;
                color: gray;
                opacity: 0.7;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                transition: opacity 0.3s ease;
                &:hover {
                    opacity: 1;
                }
            }

            .mt-modal-header {
                padding: 0 $paddingSize;
                .mt-modal-title {
                    font-size: 1.2rem;
                    padding-top: $paddingSize;
                }
            }
            .mt-modal-body {
                padding: $paddingSize;
            }

            .mt-modal-footer {
                padding: 0.75rem;
                background: rgba(0, 0, 0, 0.025);
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }

    .mt-modal-enter-active {
        transition: all $animationSpeed $timingFunction;
        .mt-modal {
            animation: modalIn $animationSpeed $timingFunction;
        }
        &.mt-modal-enter {
            opacity: 0;
        }
        &.mt-modal-enter-to {
            opacity: 1;
        }
    }
    .mt-modal-leave-active {
        transition: all $animationSpeed $timingFunction;
        .mt-modal {
            animation: modalOut $animationSpeed $timingFunction;
        }
        &.mt-modal-leave-to {
            opacity: 0;
        }
    }

    @keyframes modalIn {
        0% {
            /* border: 100px solid yellow; */
            transform: scale(0.95);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes modalOut {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(0.95);
            opacity: 0;
        }
    }
</style>
