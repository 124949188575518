<template>
    <div class="row">
        <div class="col-4">
            <p class="lead t-text-gray-500">Informações Pessoais</p>
            <p class="t-text-gray-700">
                Aqui você pode alterar suas informações pessoais como
                nome e email. Lembre-se que ao alterar seu e-mail você
                deverá verificar seu e-mail novamente.
            </p>
        </div>

        <div class="col">
            <div class="app-card">
                <div class="card-body">
                    <input-text required name="name" label="Nome" :form="form" />
                    <input-text readonly name="email" label="Email" :form="form" />

                    <button-loading class="btn btn-primary" :loading="form.submitting"
                        @click="save">
                        Salvar
                    </button-loading>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: new Form({
                id: this.$user.id,
                name: this.$user.name,
                email: this.$user.email,
            }),
        }
    },

    methods: {
        async save() {
            await this.form.save(this.route('users.index'));
            this.$toasted.global.success();
            window.location.reload();
        }
    }
}
</script>
