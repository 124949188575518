<template>
    <div class="editor">
        <editor-menu-bar
            :editor="editor"
            v-slot="{ commands, isActive }"
        >
            <div class="menubar">
                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.bold() }"
                    @click="commands.bold"
                >
                    <i class="fa fa-fw fa-bold"></i>
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.italic() }"
                    @click="commands.italic"
                >
                    <i class="fa fa-fw fa-italic"></i>
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.strike() }"
                    @click="commands.strike"
                >
                    <i class="fa fa-fw fa-strikethrough"></i>
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.underline() }"
                    @click="commands.underline"
                >
                    <i class="fa fa-fw fa-underline"></i>
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.paragraph() }"
                    @click="commands.paragraph"
                >
                    <i class="fa fa-fw fa-paragraph"></i>
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                    @click="commands.heading({ level: 1 })"
                >
                    H1
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                    @click="commands.heading({ level: 2 })"
                >
                    H2
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                    @click="commands.heading({ level: 3 })"
                >
                    H3
                </button>

                <button
                    class="menubar__button"
                    :class="{ 'is-active': isActive.blockquote() }"
                    @click="commands.blockquote"
                >
                    <i class="fa fa-fw fa-quote-right"></i>
                </button>

                <button
                    class="menubar__button"
                    @click="commands.undo"
                >
                    <i class="fa fa-fw fa-undo-alt"></i>
                </button>

                <button
                    class="menubar__button"
                    @click="commands.redo"
                >
                    <i class="fa fa-fw fa-redo-alt"></i>
                </button>

            </div>
        </editor-menu-bar>

        <editor-content
            class="editor__content"
            :editor="editor"
        />
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions';

export default {
    components: {
        EditorContent,
        EditorMenuBar,
    },

    props: {
        value: { default: null },
    },

    data() {
        return {
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                content: this.value || `
          <p>Este canal de denúncia é um espaço para que nossos colaboradores, fornecedores, clientes e público em geral possam expressar livremente e de maneira direta com o gestor do Programa de Compliance da empresa, especificamente nas áreas de direitos humanos, direitos do trabalho, proteção ao meio ambiente e combate à corrupção.</p>
        `,
            }),
        };
    },

    created() {
        this.editor.on('update', ({ getHTML }) => {
            this.$emit('input', getHTML());
        });
    },

    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>
