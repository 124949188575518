<template>
    <div class="progress" :style="{ height: height + 'px' }">
        <div
            class="progress-bar"
            :class="{ 't-bg-green-500': success, 't-bg-red-500': error }"
            :style="{ width: width + '%' }"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            width: { default: 0 },
            height: { default: 8 },
            success: { default: false, type: Boolean },
            error: { default: false, type: Boolean },
        }
    };
</script>
