<template>
    <div>
        <div class="app-card mb-3">
            <div class="card-body">
                <input-search v-model="search" @input="fetchUsers()" class="mb-0" placeholder="Pesquisar usuários por nome ou email" />
            </div>
        </div>

        <div class="app-card overflow-hidden">
            <app-table
                :headers="['ID', 'Nome', 'Email', 'CPF', 'Status', 'Último Login', 'Ações']"
                :meta="users.meta"
                :links="users.links"
                @page="fetchUsers"
                :loading="fetchingUsers"
            >
                <tr v-for="(user, index) of users.data" :key="index">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.cpf | cpf }}</td>
                    <td>
                        <badge :color="user.active ? 'green' : 'red'">
                            {{ user.active ? 'Ativo' : 'Inativo' }}
                        </badge>
                    </td>
                    <td>{{ user.last_login_at | date }}</td>
                    <td>
                        <a
                            v-if="user.active && user.id !== $user.id && user.id !== 1"
                            :href="route('admin.users.inactivate', user)"
                            class="mr-1 t-text-red-500"
                            title="Bloquear"
                        >
                            <i class="fa fa-user-slash"></i>
                        </a>
                        <a
                            v-if="!user.active && user.id !== $user.id && user.id !== 1"
                            :href="route('admin.users.activate', user)"
                            class="mr-1 t-text-gray-700"
                            title="Desbloquear"
                        >
                            <i class="fa fa-user-check"></i>
                        </a>

                        <a v-if="user.id !== $user.id" :href="route('admin.impersonate', user)" class="t-text-gray-700" title="Impersonar">
                            <i class="fa fa-user-secret"></i>
                        </a>
                    </td>
                </tr>
            </app-table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        created() {
            this.fetchUsers();
        },

        data: () => ({
            users: {
                data: []
            },
            search: null,
            fetchingUsers: false
        }),

        methods: {
            async fetchUsers(page = 1) {
                try {
                    this.fetchingUsers = true;
                    const params = { page, search: this.search };
                    const { data } = await axios.get(route('admin.users.index'), { params });
                    this.users = data;
                } finally {
                    this.fetchingUsers = false;
                }
            }
        }
    };
</script>
