<template>
    <modal ref="modal">
        <h4 slot="title">Cadastro de Empresa</h4>

        <h3 class="t-text-gray-600 t-text-lg t-border-b t-border-gray-200 mb-2 t-text-center">Empresa</h3>

        <input-file
            :form="form"
            name="logo_id"
            v-model="form.logo_id"
            :file="form.logo"
            label="Logo"
        />

        <input-text
            :form="form"
            name="name"
            required
            v-model="form.name"
            label="Nome da Empresa"
            placeholder="Razão Social"
        />

        <input-text
            :form="form"
            name="cnpj"
            required
            v-model="form.cnpj"
            v-mask="['##.###.###/####-##']"
            label="CNPJ"
            placeholder="##.###.###/####-##"
        />

        <input-text
            :form="form"
            name="address_line"
            required
            v-model="form.address_line"
            label="Logradouro"
        />

        <input-text
            :form="form"
            name="address_number"
            required
            v-model="form.address_number"
            label="Número"
        />

        <input-text
            :form="form"
            name="address_city"
            required
            v-model="form.address_city"
            label="Município"
        />

        <select-state
            v-model="form.address_state"
            :form="form"
            name="address_state"
        />

        <input-text
            :form="form"
            name="address_zipcode"
            required
            v-model="form.address_zipcode"
            label="CEP"
            v-mask="['##.###-###']"
        />

        <input-text
            :form="form"
            name="phone"
            required
            v-model="form.phone"
            label="Telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
        />

        <input-text
            :form="form"
            name="phone_secondary"
            v-model="form.phone_secondary"
            label="Telefone 2"
            v-mask="['(##) ####-####', '(##) #####-####']"
        />
        <input-text
            :form="form"
            name="state_subscription"
            v-model="form.state_subscription"
            label="Inscrição Estadual"
        />
        <input-text
            :form="form"
            name="city_subscription"
            v-model="form.city_subscription"
            label="Inscrição Municipal"
        />
        <input-text
            :form="form"
            name="cnpj_head"
            v-model="form.cnpj_head"
            v-mask="['##.###.###/####-##']"
            label="CNPJ da Matriz"
            placeholder="##.###.###/####-##"
        />
        <input-text
            :form="form"
            name="main_activity"
            v-model="form.main_activity"
            label="Atividade da Empresa"
        />

        <div v-if="!form.id">
            <h3 class="t-text-gray-600 t-text-lg t-border-b t-border-gray-200 mt-5 mb-2 t-text-center">Responsável</h3>

            <div class="t-flex t-justify-center t-mb-3">
                <div class="custom-control custom-radio custom-control-inline">
                    <input
                        type="radio"
                        v-model="form.new_owner"
                        :value="true"
                        id="new-owner"
                        name="choose-owner"
                        class="custom-control-input"
                    />
                    <label
                        class="custom-control-label"
                        for="new-owner"
                    >Novo Responsável</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                    <input
                        type="radio"
                        v-model="form.new_owner"
                        :value="false"
                        id="select-owner"
                        name="choose-owner"
                        class="custom-control-input"
                    />
                    <label
                        class="custom-control-label"
                        for="select-owner"
                    >Responsável Existente</label>
                </div>
            </div>

            <div v-if="form.new_owner">
                <input-text
                    :form="form"
                    name="owner.name"
                    required
                    v-model="form.owner.name"
                    label="Nome do Responsável"
                />
                <input-text
                    :form="form"
                    name="owner.email"
                    required
                    v-model="form.owner.email"
                    label="Email"
                />
                <input-text
                    :form="form"
                    name="owner.cpf"
                    required
                    v-model="form.owner.cpf"
                    label="CPF"
                    placeholder="###.###.###-##"
                    v-mask="['###.###.###-##']"
                />
                <input-text
                    :form="form"
                    name="owner.phone"
                    required
                    v-model="form.owner.phone"
                    label="Telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                />
                <input-text
                    :form="form"
                    name="owner.phone_secondary"
                    v-model="form.owner.phone_secondary"
                    label="Telefone 2"
                    class="mb-0"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                />
            </div>

            <div v-if="!form.new_owner">
                <input-text
                    label="Email do usuário"
                    placeholder="Escreva o e-mail do usuário já existente"
                    class="mb-0"
                    v-model="form.owner_email"
                    :form="form"
                    name="owner_email"
                />
            </div>
        </div>

        <div
            slot="footer"
            class="t-flex t-justify-end"
        >
            <button
                class="btn btn-default ml-3"
                @click="$refs.modal.close()"
            >Cancelar</button>
            <button-loading
                :loading="saving"
                class="btn btn-success ml-3"
                @click="save"
            >Salvar</button-loading>
        </div>
    </modal>
</template>

<script>
import { Form } from '@/utils';

export default {
    data: () => ({
        form: new Form({
            logo_id: null,
            owner: {},
            new_owner: true,
        }),
        saving: false,
    }),

    methods: {
        setup(company = { owner: {}, new_owner: true }) {
            this.form = new Form(company);
            this.form.logo_id = company.logo && company.logo.id;

            return this;
        },

        open() {
            this.$refs.modal.open();
            return this;
        },

        close() {
            this.$refs.modal.close();

            return this;
        },

        async save() {
            try {
                this.saving = true;
                const { data: company, status } = await this.form.save(this.route('admin.companies.index'));
                const emailSentMessage = status === 201 ? `Um e-mail foi enviado para ${this.form.owner.email} contendo as informações de acesso.` : '';
                this.$toasted.success(`Empresa salva com sucesso. ${emailSentMessage}`);
                this.$emit('saved', company);

                this.setup();
                return this.close();
            } finally {
                this.saving = false;
            }
        },
    },
};
</script>
