<template>
    <div class="form-group">
        <label :class="[{
            'text-danger': form.errors.has(name)
        }]" v-if="label">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>

        <input
            v-model="form[name]"
            @input="$emit('input', $event.target.value)"
            v-bind="$props"
            :class="['form-control', inputClass, {
                'is-invalid': form.errors.has(name)
            }]"/>
        <p class="invalid-feedback d-block" v-if="form.errors.has(name)">
            {{ form.errors.get(name) }}
        </p>
    </div>
</template>

<script>
    import { Form } from "@/utils";

    export default {
        props: {
            value: { default: null },
            label: { default: null },
            inputClass: { default: "" },
            type: { default: "text" },
            placeholder: { default: null },
            name: { default: null, type: String },
            form: { default: () => new Form(), type: Form },
            required: { default: false, type: Boolean },
            readonly: { default: false, type: Boolean },
        },

        watch: {
            value() {
                this.form.errors.clear(this.name);
            }
        }
    };
</script>
