<template>
    <div>
        <div v-if="iAmOwner">
            <page-title>Convites</page-title>
            <app-card class="mb-5">
                <div class="card-body">
                    <input-text v-model="form.email" name="email" :form="form" placeholder="E-mail" label="Enviar convite de membro" />

                    <button-loading @click="sendInvitation" :loading="sendingInvitation" class="btn btn-primary"
                        >Enviar Convite</button-loading
                    >
                </div>
                <app-table
                    v-if="invitations.length"
                    table-class="table-striped border-top"
                    :headers="['Convites pendentes', 'Enviado Em', '']"
                >
                    <tr v-for="invitation of invitations" :key="invitation.id">
                        <td>{{ invitation.email }}</td>
                        <td>{{ invitation.created_at | date }}</td>
                        <td>
                            <a :href="route('invitations.resend', invitation)" class="btn t-p-1 t-py-0 btn-sm">
                                <i class="fa fa-redo-alt"></i>
                            </a>

                            <button @click="remove(invitation)" class="btn t-p-1 t-py-0 btn-sm">
                                <i class="fa fa-remove"></i>
                            </button>
                        </td>
                    </tr>
                </app-table>
            </app-card>
        </div>

        <page-title>Lista de Membros</page-title>

        <app-card>
            <app-table :headers="tableHeaders">
                <tr v-for="member of members.data" :key="member.id">
                    <td>{{ member.id }}</td>
                    <td>{{ member.name }}</td>
                    <td>{{ member.email }}</td>
                    <td>{{ member.cpf | cpf }}</td>
                    <td>{{ member.id === company.owner_id ? 'Responsável' : 'Membro' }}</td>
                    <td v-if="iAmOwner">
                        <button
                            v-if="member.id !== $user.id"
                            @click="confirmSwitchOwner(member)"
                            class="btn t-py-0 t-px-1 t-leading-5 t-text-gray-600"
                        >
                            <i class="fa fa-people-arrows"></i>
                        </button>

                        <button
                            v-if="member.id !== $user.id"
                            @click="confirmRemove(member)"
                            class="btn t-text-red-500 t-py-0 t-leading-5 t-px-1"
                        >
                            <i class="fa fa-sign-out-alt"></i>
                        </button>
                    </td>
                </tr>
            </app-table>
        </app-card>

        <modal ref="confirmRemoveMember" v-if="iAmOwner">
            <p class="lead t-mb-3 t-mr-6" v-if="memberToRemove">
                Tem certeza que deseja remover
                <span class="t-font-bold">{{ memberToRemove.name }}</span>
                da empresa
                <span class="t-font-bold">{{ company.name }}</span
                >?
            </p>

            <p class="t-text-gray-500 t-text-sm">
                Esta ação não remove o cadastro do usuário, apenas o remove da empresa. Seu cadastro permanecerá e ele continuará fazendo
                parte de outras empresas.
            </p>

            <div class="t-mt-3 t-flex t-justify-end">
                <button @click="$refs.confirmRemoveMember.close()" class="btn t-mr-2">Cancelar</button>

                <button-loading @click="removeMember(memberToRemove)" :loading="removingMember" class="btn btn-danger">
                    Remover
                </button-loading>
            </div>
        </modal>

        <modal ref="confirmSwitchOwner" v-if="iAmOwner">
            <p class="lead t-mb-3 t-mr-6" v-if="ownerCandidate">
                Tem certeza que deseja tornar
                <span class="t-font-bold">{{ ownerCandidate.name }}</span>
                como responsável pela empresa
                <span class="t-font-bold">{{ company.name }}</span
                >?
            </p>

            <!-- <p class="t-text-gray-500 t-text-sm">
                Esta ação é irreversível e só poderá ser desfeita depois que o
            </p> -->

            <div class="t-mt-3 t-flex t-justify-end">
                <button @click="$refs.confirmSwitchOwner.close()" class="btn t-mr-2">Cancelar</button>

                <button-loading @click="switchOwner(ownerCandidate)" class="btn btn-primary">
                    Alterar Responsável
                </button-loading>
            </div>
        </modal>
    </div>
</template>

<script>
    import { Form } from '@/utils';

    export default {
        props: {
            initialData: { required: true, type: Object },
            company: { required: true, type: Object }
        },

        data() {
            return {
                members: this.initialData,
                form: new Form({
                    company_id: this.company.id
                }),
                invitations: this.company.invitations || [],
                sendingInvitation: false,
                fetchingMembers: false,
                removingMember: false,
                memberToRemove: null,
                ownerCandidate: null
            };
        },

        computed: {
            tableHeaders() {
                const headers = ['ID', 'nome', 'email', 'cpf', 'Perfil'];

                if (this.iAmOwner) {
                    headers.push('Ações');
                }

                return headers;
            },

            iAmOwner() {
                return this.company.owner_id === this.$user.id;
            }
        },

        methods: {
            async sendInvitation() {
                try {
                    this.sendingInvitation = true;
                    await this.form.post(this.route('invitations.store'));
                    await this.fetchInvitations();
                    this.$toasted.show(`Convite enviado com sucesso para ${this.form.email}`);
                    this.form = new Form({
                        company_id: this.company.id
                    });
                } finally {
                    this.sendingInvitation = false;
                }
            },

            async fetchInvitations() {
                const { data: invitations } = await this.$http.get(this.route('companies.get-invitations', this.company));
                this.invitations = invitations;
            },

            async fetchMembers() {
                try {
                    this.fetchingMembers = true;
                    const { data: members } = await this.$http.get(this.route('companies.get-members', this.company));
                    this.members = members;
                } finally {
                    this.fetchingMembers = false;
                }
            },

            async remove(invitation) {
                await this.$http.delete(this.route('invitations.destroy', invitation));
                window.location.reload();
            },

            async removeMember(member) {
                try {
                    this.removingMember = true;
                    await this.$http.delete(this.route('companies.remove-member', [this.company, member]));
                    this.$toasted.show('Membro removido da empresa com sucesso');
                    await this.fetchMembers();
                } finally {
                    this.removingMember = false;
                    this.$refs.confirmRemoveMember.close();
                }
            },

            async switchOwner(member) {
                await this.$http.put(this.route('companies.switch-owner', [this.company, member]));
                window.location.reload();
            },

            async confirmRemove(member) {
                this.memberToRemove = member;
                this.$refs.confirmRemoveMember.open();
            },

            async confirmSwitchOwner(member) {
                this.ownerCandidate = member;
                this.$refs.confirmSwitchOwner.open();
            }
        }
    };
</script>
