<template>
    <button :type="type" @click="$emit('click', $event)" :disabled="loading || disabled">
        <span v-if="loading" :class="{ 'mr-2': $slots.default }">
            <i class="fa fa-spinner fa-spin"></i>
        </span>
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        loading: { default: false, type: Boolean },
        type: { default: 'submit' },
        disabled: { default: false, type: Boolean }
    }
};
</script>
