var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[(_vm.label)?_c('label',{class:[{
        'text-danger': _vm.form.errors.has(_vm.name)
    }]},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._v(" "),(((_vm.$props).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.name]),expression:"form[name]"}],class:['form-control', _vm.inputClass, {
            'is-invalid': _vm.form.errors.has(_vm.name)
        }],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form[_vm.name])?_vm._i(_vm.form[_vm.name],null)>-1:(_vm.form[_vm.name])},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){var $$a=_vm.form[_vm.name],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, _vm.name, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, _vm.name, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, _vm.name, $$c)}}}},'input',_vm.$props,false)):(((_vm.$props).type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.name]),expression:"form[name]"}],class:['form-control', _vm.inputClass, {
            'is-invalid': _vm.form.errors.has(_vm.name)
        }],attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.form[_vm.name],null)},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$set(_vm.form, _vm.name, null)}}},'input',_vm.$props,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.name]),expression:"form[name]"}],class:['form-control', _vm.inputClass, {
            'is-invalid': _vm.form.errors.has(_vm.name)
        }],attrs:{"type":(_vm.$props).type},domProps:{"value":(_vm.form[_vm.name])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, _vm.name, $event.target.value)},function($event){return _vm.$emit('input', $event.target.value)}]}},'input',_vm.$props,false)),_vm._v(" "),(_vm.form.errors.has(_vm.name))?_c('p',{staticClass:"invalid-feedback d-block"},[_vm._v("\n        "+_vm._s(_vm.form.errors.get(_vm.name))+"\n    ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }