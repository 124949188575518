<template>
    <div
        class="mx-auto t-h-screen t-bg-center t-bg-cover py-5"
        :style="background"
    >
        <page-title class="t-text-center">{{ __('Complaint') }}</page-title>

        <p class="t-text-xl t-text-center mt-5 mb-3">
            {{ __('Enter the number of the monitoring protocol you wish to consult') }}
        </p>

        <div
            class="mx-auto mb-4"
            style="max-width: 500px"
        >
            <input-text
                v-model="protocol"
                input-class="t-bg-gray-300 t-border-gray-300 t-border-2 t-rounded-lg
                hover:t-cursor-pointer hover:t-border-gray-500 focus:t-border-blue-500 focus:t-bg-white t-text-4xl"
                :placeholder="__('Protocol')"
            />
        </div>

        <div class="t-text-center">
            <primary-button
                @click="consultProtocol"
                :color="company.primary_color"
                class="btn t-mx-auto t-rounded-lg t-px-8 t-py-4 t-text-xl btn-primary t-uppercase"
            >
                {{ __('Consult') }}
            </primary-button>

            <a
                :href="route('complaints.portal', company.slug)"
                class="btn t-mx-auto t-rounded-lg t-px-8 t-py-4 t-text-xl t-uppercase"
            >
                {{ __('Back') }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        company: { required: true, type: Object },
    },

    data: () => ({
        protocol: null,
    }),

    computed: {
        background() {
            if (this.company.background_type === 'image') {
                return { backgroundImage: `url(${this.company.background_url})` };
            }

            if (this.company.background_type === 'color') {
                return {
                    backgroundColor: this.company.background_color,
                };
            }

            return null;
        },
    },

    methods: {
        consultProtocol() {
            window.location.href = this.route('complaints.view', {
                company: this.company.slug,
                protocol: this.protocol,
            });
        },
    },
};
</script>
