<template>
    <div
        @click="toggle"
        class="t-flex bg-white t-p-2 t-rounded t-border t-border-gray-400 t-items-center hover:t-bg-gray-100 t-cursor-pointer"
    >
        <div
            class="t-w-6 t-h-6 t-mr-2 t-border t-rounded"
            :style="{backgroundColor: color}"
        ></div>
        <div
            v-if="label"
            class="t-text-base t-leading-3"
        >{{ label }}</div>

        <div
            v-if="opened"
            @click.stop="() => null"
            class="ml-auto t-flex t-flex-col"
        >
            <color-picker
                :value="color"
                @input="onInput"
            ></color-picker>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: { default: null },
        value: { default: 'white' },
        type: { default: 'hex' },
    },

    data() {
        return {
            color: this.value,
            opened: false,
        };
    },

    watch: {
        value() {
            this.color = this.value;
        },
    },

    methods: {
        onInput(color) {
            this.color = color[this.type];
            this.$emit('input', this.color);
        },

        toggle() {
            this.opened = !this.opened;
        },
    },
};
</script>
