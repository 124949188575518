var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{class:[
            {
                'text-danger': _vm.form.errors.has(_vm.name)
            }
        ]},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('v-date-picker',{attrs:{"value":_vm.innerValue,"popover":{ visibility: 'focus' },"input-props":{
            class: _vm.inputClass,
            placeholder: _vm.placeholder
        },"mode":_vm.range ? 'range' : '',"locale":"pt-BR"},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }