<template>
    <div>
        <div class="app-card overflow-hidden">
            <app-table
                :headers="['ID', 'Data', 'Personificador', 'Executor', 'Evento', 'Entidade', 'URL', 'Endereço IP', 'De', 'Para']"
                :meta="audits.meta"
                :links="audits.links"
                @page="fetchAudits"
                :data="audits.data"
                :loading="fetchingAudits"
            >
                <tr class="t-whitespace-no-wrap t-text-xs" v-for="(audit, index) of audits.data" :key="index">
                    <td class="align-middle p-1 px-2">{{ audit.id }}</td>
                    <td class="align-middle p-1 px-2">{{ audit.created_at | date }}</td>
                    <td class="align-middle p-1 px-2">{{ audit.impersonator && audit.impersonator.name }}</td>
                    <td class="align-middle p-1 px-2">{{ executor(audit) }}</td>
                    <td class="align-middle p-1 px-2">{{ audit.event }}</td>
                    <td class="align-middle p-1 px-2">{{ auditable(audit) }}</td>
                    <td class="align-middle p-1 px-2">{{ audit.url }}</td>
                    <td class="align-middle p-1 px-2">{{ audit.ip_address }}</td>
                    <td class="align-middle p-1 px-2">{{ audit.old_values }}</td>
                    <td class="align-middle p-1 px-2">{{ audit.new_values }}</td>
                </tr>
            </app-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    created() {
        this.fetchAudits();
    },

    data: () => ({
        audits: {
            data: [],
        },
        fetchingAudits: false,
    }),

    methods: {
        async fetchAudits(page = 1) {
            try {
                this.fetchingAudits = true;
                const params = { page };
                const { data } = await axios.get(route('admin.audits.index'), { params });
                this.audits = data;
            } finally {
                this.fetchingAudits = false;
            }
        },

        executor(audit) {
            const user = audit.user;
            if (user) return audit.user.name;

            return '[Job]';
        },

        auditable(audit) {
            return audit.auditable && audit.auditable.name || (`${audit.auditable_type} (ID: ${audit.id})`)
        },
    }
}
</script>
