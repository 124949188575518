import './bootstrap';
import './utils';
import Vue from 'vue';
import Axios from 'axios';
import Lang from 'lang.js';

window.Vue = Vue;

function requireAll(r) { r.keys().forEach(r); }
function registerGlobalComponentsIn(context) {
    context.keys().map((key) => Vue.component(key.split('/').pop().split('.')[0], context(key).default));
}

requireAll(require.context('./plugins', true, /\.js$/));

const filters = require.context('./filters', true, /\.js$/);
filters.keys().map((key) => Vue.filter(key.split('/').pop().split('.')[0], filters(key).default));

registerGlobalComponentsIn(require.context('./pages', true, /\.vue$/i));
registerGlobalComponentsIn(require.context('./components/shared', false, /\.vue$/i));

const lang = new Lang({
    locale: window.Globals.defaultLocale,
    fallback: window.Globals.fallbackLocale,
    messages: window.Globals.i18n,
});

Vue.prototype.route = window.route;
Vue.prototype.$user = window.Globals.user;
Vue.prototype.$http = Axios;
Vue.prototype.$primaryColor = '#3490dc';
Vue.prototype.$lang = lang;
Vue.prototype.trans = lang.trans.bind(lang);
Vue.prototype.transChoice = lang.transChoice.bind(lang);
// eslint-disable-next-line no-underscore-dangle
Vue.prototype.__ = (key, replacements) => {
    const translation = lang.trans(`__JSON__.${key}`, replacements);
    return translation.startsWith('__JSON__.') ? key : translation;
};

function registerGlobals() {
    Object.keys(window.Globals).forEach((key) => Vue.prototype[`\$${key}`] = window.Globals[key]);
}
registerGlobals();

new Vue({
    el: '#app',
});
