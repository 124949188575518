<template>
    <div>
        <label
            :class="[
                {
                    'text-danger': form.errors.has(name)
                }
            ]"
            v-if="label"
        >
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>

        <v-date-picker
            :value="innerValue"
            @input="$emit('input', $event)"
            :popover="{ visibility: 'focus' }"
            :input-props="{
                class: inputClass,
                placeholder
            }"
            :mode="range ? 'range' : ''"
            locale="pt-BR"
        >
        </v-date-picker>
    </div>
</template>

<script>
import { Form } from '@/utils';
export default {
    props: {
        value: { default: null },
        inputClass: { default: 'form-control' },
        label: { default: null },
        form: { default: () => new Form(), type: Form },
        placeholder: { default: null },
        name: { default: null },
        required: { default: false, type: Boolean },
        range: { default: false, type: Boolean },
    },

    data() {
        return {
            innerValue: null
        };
    },

    watch: {
        value() {
            this.valueUpdated();
        }
    },

    mounted() {
        this.valueUpdated();
    },

    methods: {
        input(event) {
            this.$emit('input', event);
        },

        valueUpdated() {
            this.innerValue = typeof this.value === 'string' ? new Date(this.value) : this.value;
        }
    }
};
</script>
