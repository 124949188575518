<template>
    <div>
        <h3 class="t-text-2xl t-text-center t-uppercase mt-5">{{ __('New Complaint') }}</h3>
        <h3 class="t-text-base mb-4 t-text-center t-uppercase t-opacity-50">{{ company.name }}</h3>

        <div class="mx-auto t-bg-gray-200 t-p-8 t-rounded-lg">
            <input-text
                name="reporter_name"
                :form="form"
                :placeholder="__('Your name')"
                v-model="form.reporter_name"
                :label="__('Name (Optional)')"
            />

            <input-text
                name="reporter_email"
                :form="form"
                :placeholder="__('Your email address')"
                v-model="form.reporter_email"
                :label="__('Email (Optional)')"
            />

            <div class="form-row">
                <div class="col">
                    <input-select
                        required
                        name="type"
                        :form="form"
                        v-model="form.type"
                        :placeholder="__('Select an Option')"
                        :label="__('Type of Request')"
                        :options="types"
                    />
                </div>

                <div class="col">
                    <input-select
                        required
                        name="section"
                        :form="form"
                        v-model="form.section"
                        :placeholder="__('Select an Option')"
                        :label="__('Area / Department')"
                        :options="sections"
                    />
                </div>
            </div>

            <input-file
                v-model="form.file"
                accept=".pdf,image/*"
                :form="form"
                :label="__('Attach File - PDF, JPEG, PNG. (Optional)')"
                :placeholder="__('Select a file')"
                :preview-always="false"
            />

            <input-textarea
                name="description"
                :form="form"
                :placeholder="__('Enter your report here in detail')"
                rows="10"
                v-model="form.description"
                :label="__('Report Description')"
            />

            <div class="t-flex t-justify-end">
                <button
                    @click="$emit('back')"
                    class="btn btn-link btn-lg mr-2"
                    :style="{color: company.primary_color}"
                >{{ __('Back') }}</button>

                <primary-button
                    @click="submit"
                    :color="company.primary_color"
                    :loading="form.submitting"
                    class="btn btn-primary btn-lg"
                >
                    {{ __('Send') }}
                </primary-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Form } from '@/utils';

export default {
    props: {
        company: { required: true, type: Object },
    },

    data() {
        return {
            form: new Form(),
            types: Globals.constants.complaint_types,
            sections: Globals.constants.complaint_sections,
        };
    },

    methods: {
        async submit() {
            const { data } = await this.form.post(
                this.route('complaints.store', this.company),
            );
            this.$emit('submitted', data);
        },
    },
};
</script>
