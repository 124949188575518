import { render, staticRenderFns } from "./InputFile.vue?vue&type=template&id=64e05884&scoped=true&"
import script from "./InputFile.vue?vue&type=script&lang=js&"
export * from "./InputFile.vue?vue&type=script&lang=js&"
import style0 from "./InputFile.vue?vue&type=style&index=0&id=64e05884&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e05884",
  null
  
)

export default component.exports