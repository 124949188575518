<template>
    <div class="mb-3">
        <div class="t-flex t-items-center">
            <div
                v-if="previewAlways || srcPreview"
                @click="browserFiles"
                class="img-preview t-cursor-pointer t-rounded t-rounded-md t-bg-gray-300"
            >
                <img
                    v-if="srcPreview"
                    :src="srcPreview"
                    class="t-object-cover t-rounded"
                >
                <div
                    v-if="!srcPreview"
                    class="t-flex t-items-center t-justify-center t-w-full t-h-full"
                >
                    <div class="t-text-gray-400">
                        <i :class="icon"></i>
                    </div>
                </div>
            </div>

            <input
                type="file"
                @change="onFileChange"
                :multiple="false"
                :accept="accept"
                ref="input"
                class="absolute t-hidden"
            >

            <div class="t-w-full t-relative mb-2">
                <input-text
                    class="mb-0"
                    input-class="bg-white t-cursor-pointer"
                    readonly
                    :form="form"
                    @click.native="browserFiles"
                    :label="label"
                    :placeholder="filename"
                />

                <progress-bar
                    v-if="!uploader.pending"
                    class="t-absolute"
                    :success="uploader.completed"
                    :error="uploader.failed"
                    style="top: 65px; left: 0; right: 0;"
                    :width="uploader.percentage"
                    :height="4"
                />

                <p
                    v-if="uploader.error"
                    class="d-block invalid-feedback"
                >{{ uploader.error }}</p>
            </div>
        </div>

    </div>
</template>

<script>
import { Uploader, Form } from '@/utils';

export default {
    props: {
        label: { default: null },
        placeholder: { default: 'Selecione um arquivo' },
        type: { default: 'image' },
        accept: { default: 'image/*' },
        icon: { default: 'fas fa-file-upload fa-2x' },
        file: { default: null, type: Object },
        previewAlways: { default: true },
        form: { default: () => new Form(), type: Form },
    },

    data() {
        return {
            srcPreview: this.file && this.file.url,
            filename: this.placeholder,
            uploader: new Uploader(),
        };
    },

    methods: {
        browserFiles() {
            this.$refs.input.click();
        },

        resetState() {
            this.filename = this.placeholder;
            this.srcPreview = null;
            this.uploader = new Uploader();
        },

        onFileChange(e) {
            const file = e.target.files[0];
            this.resetState();

            if (!file) {
                return;
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                if (file.type.includes('image/')) {
                    this.srcPreview = event.target.result;
                    this.$emit('preview', this.srcPreview);
                }
                this.filename = file.name;
                this.startUploading(file);
            };
            reader.readAsDataURL(file);
        },

        async startUploading(file) {
            const { data: uploaded } = await this.uploader.setFile(file)
                .uploadTo(this.route('files.store'));

            this.$emit('input', uploaded.id);
        },
    },
};
</script>

<style lang="scss" scoped>
    .img-preview {
        width: 68px;
        height: 68px;
        min-width: 68px;
        min-height: 68px;
        margin-right: 1rem;
        padding: 3px;

        img {
            width: 100%;
            height: 100%;
        }
    }
</style>
