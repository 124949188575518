<template>
    <div>
        <div class="t-flex t-items-center t-justify-between mb-3">
            <h3 class="t-text-2xl t-text-gray-700">Empresas</h3>

            <button
                @click="() => $refs.form.setup().open()"
                class="btn btn-primary"
            >
                <i class="fa fa-plus mr-2"></i>
                Cadastrar Empresa
            </button>

            <company-form-modal
                @saved="fetchCompanies(page)"
                ref="form"
            />
        </div>

        <div class="app-card mb-3">
            <div class="card-body">
                <input-search
                    v-model="search"
                    @input="fetchCompanies()"
                    class="mb-0"
                    placeholder="Pesquisar empresas por nome ou CNPJ"
                />
            </div>
        </div>

        <div class="app-card overflow-hidden">
            <app-table
                :headers="['', 'ID', 'Nome', 'Status', 'slug', 'Responsável', 'CNPJ', 'Tipo', 'Ações']"
                :meta="companies.meta"
                :links="companies.links"
                @page="fetchCompanies"
                :loading="fetchingCompanies"
            >
                <tr
                    v-for="(company, index) of companies.data"
                    :key="index"
                >
                    <td class="align-middle">
                        <div
                            v-if="company.logo"
                            class="t-flex t-rounded t-overflow-hidden"
                            style="min-width: 4rem; max-width: 4rem; max-height: 2.5rem"
                        >
                            <img
                                class="rounded t-object-cover"
                                :src="company.logo.url"
                                alt=""
                            />
                        </div>
                    </td>
                    <td class="align-middle t-whitespace-no-wrap">{{ company.id }}</td>
                    <td class="align-middle t-whitespace-no-wrap">{{ company.name }}</td>
                    <td class="align-middle t-whitespace-no-wrap">
                        <badge :color="company.active ? 'green' : 'red'">{{ company.active ? 'Ativa' : 'Inativa' }}</badge>
                    </td>
                    <td class="align-middle t-whitespace-no-wrap">
                        <a
                            class="text-primary"
                            :href="route('complaints.portal', company.slug)"
                            target="_blank"
                        >
                            {{ company.slug }}
                        </a>
                    </td>
                    <td class="align-middle t-whitespace-no-wrap">{{ company.owner.name }}</td>
                    <td class="align-middle t-whitespace-no-wrap">{{ company.cnpj | cnpj }}</td>
                    <td class="align-middle t-whitespace-no-wrap">{{ company.cnpj_head ? 'Filial' : 'Matriz' }}</td>
                    <td class="align-middle t-whitespace-no-wrap">
                        <div class="t-flex t-items-center">
                            <input-switch
                                @input="toggleStatus(company, $event)"
                                :value="company.active"
                            ></input-switch>

                            <button
                                @click="edit(company)"
                                class="btn btn-sm"
                            >
                                <span class="t-text-gray-700">
                                    <i class="fa fa-pencil-alt"></i>
                                </span>
                            </button>
                        </div>
                    </td>
                </tr>
            </app-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import CompanyFormModal from '@/components/companies/CompanyFormModal';

export default {
    components: { CompanyFormModal },

    created() {
        this.fetchCompanies();
    },

    data: () => ({
        companies: {
            data: [],
        },
        search: null,
        page: 1,
        fetchingCompanies: false,
    }),

    methods: {
        async fetchCompanies(page = 1) {
            try {
                this.page = page;
                this.fetchingCompanies = true;
                const params = { page, search: this.search };
                const { data } = await axios.get(route('admin.companies.index'), { params });
                this.companies = data;
            } finally {
                this.fetchingCompanies = false;
            }
        },

        edit(company) {
            this.$refs.form.setup(company).open();
        },

        async toggleStatus(company, active) {
            await axios.post(route('admin.companies.toggle-status', company));
            this.fetchCompanies(this.page);
        },
    },
};
</script>
