<template>
    <div
        class="t-pt-1 t-bg-center t-min-h-screen t-bg-cover"
        :style="background"
    >
        <div
            class="width t-relative t-pb-12"
            v-if="'home' === page"
        >
            <div
                class="t-flex t-justify-center t-max-w-xs t-mx-auto mt-5 mb-4"
                v-if="company.logo_url"
            >
                <div>
                    <img
                        :src="company.logo_url"
                        alt=""
                    />
                </div>
            </div>
            <h1
                class="t-text-4xl mb-0 t-text-center t-uppercase"
                :class="{ 'mt-5': !company.logo_url }"
            >{{ __('Reporting Channel') }}</h1>
            <div class="t-text-xl t-uppercase t-opacity-50 mb-4 t-text-center">{{ company.name }}</div>

            <div class="t-bg-gray-200 t-p-8 t-rounded-lg">
                <div
                    class="editor__content"
                    v-html="company.portal_message || __(`<p>This reporting channel is a space for employees, suppliers, customers and the general public to express themselves freely and directly with the company's Compliance Program manager, specifically in the areas of human rights, work rights, protection of the environment and combating corruption.</p>`)"
                >
                </div>

                <p class="lead mt-4">{{ __('Click on the desired option') }}:</p>

                <div class="mt-3 t-flex t-items-center t-justify-center">
                    <primary-button
                        @click="page = 'form'"
                        class="t-rounded-lg t-shadow-lg mr-4 t-py-4 t-px-6 t-uppercase t-text-xl"
                        :color="company.primary_color"
                    >
                        {{ __('Open Complaint') }}
                    </primary-button>

                    <primary-button
                        :href="route('complaints.consult', company.slug)"
                        :color="company.primary_color"
                        class="t-rounded-lg t-shadow-lg t-py-4 t-px-6 t-uppercase t-text-xl"
                    >
                        {{ __('Consult Complaint') }}
                    </primary-button>
                </div>
            </div>

            <a
                v-if="!editMode && $user && $user.id === company.owner_id"
                :href="route('settings.companies.customize-portal', company)"
                class="t-absolute btn btn-primary t-top-0 t-right-0"
            >
                <i class="fa fa-pencil-alt t-mr-2"></i>
                Editar
            </a>
        </div>

        <complaint-form
            v-if="'form' === page"
            :company="company"
            class="width my-5"
            @back="page = 'home'"
            @submitted="displayProtocol"
        />

        <complaint-view-protocol
            v-if="'protocol' === page"
            :complaint="complaint"
            @newComplaint="page = 'form'"
        />
    </div>
</template>

<script>
import ComplaintForm from '@/components/complaints/ComplaintForm';
import ComplaintViewProtocol from '@/components/complaints/ComplaintViewProtocol';

export default {
    components: {
        ComplaintForm,
        ComplaintViewProtocol,
    },

    props: {
        company: { required: true, type: Object },
        editMode: { default: false, type: Boolean },
    },

    data() {
        return {
            page: 'home',
            complaint: null,
        };
    },

    computed: {
        background() {
            if (this.company.background_type === 'image') {
                return { backgroundImage: `url(${this.company.background_url})` };
            }

            if (this.company.background_type === 'color') {
                return {
                    backgroundColor: this.company.background_color,
                };
            }

            return null;
        },
    },

    methods: {
        displayProtocol(complaint) {
            this.page = 'protocol';
            this.complaint = complaint;
        },
    },
};
</script>

<style lang="scss" scoped>
    .width {
        max-width: 800px;
        margin: 0 auto;
    }
</style>
