<template>
    <div class="row">
        <div class="col-4">
            <p class="lead t-text-gray-500">Alterar Senha</p>
            <p class="t-text-gray-700">
                Você pode alterar sua senha aqui, mas para isso você
                precisa informar sua senha atual.
            </p>
        </div>

        <div class="col">
            <div class="app-card">
                <div @keyup.enter="save" class="card-body">
                    <input-text required type="password" name="old_password" label="Senha Atual" :form="form" />
                    <input-text required type="password" name="password" label="Nova Senha" :form="form" />
                    <input-text required type="password" name="password_confirmation" label="Confirmação da Nova Senha" :form="form" />

                    <button-loading class="btn btn-primary" :loading="form.submitting"
                        @click="save">
                        Alterar Senha
                    </button-loading>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            form: new Form(),
        }
    },

    methods: {
        async save() {
            await this.form.put(this.route('users.change-password'));
            this.$toasted.global.success();
            this.form.reset();
        }
    }
}
</script>
