<template>
    <div>
        <input-text class="mb-0" v-bind="$props" v-model="search" />
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    props: ['value', 'placeholder', 'label'],

    data: () => ({ search: null }),

    watch: {
        search: _.debounce(function (search) {
            this.$emit('input', search);
        }, 500)
    },
}
</script>
