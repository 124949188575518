<template>
    <div>
        <div class="app-card mb-3 border t-border-orange-400">
            <div class="card-body t-flex t-items-center">
                <div class="t-flex t-flex-1 mr-3">
                    <vue-multiselect
                        v-model="selectedAdmins"
                        :options="userOptions"
                        track-by="id"
                        label="name"
                        hide-selected
                        searchable
                        multiple
                        placeholder="Digite para pesquisar por nome ou email"
                        :close-on-select="false"
                        :internal-search="false"
                        :loading="fetchingUsers"
                        @search-change="fetchUsers"
                    >
                        <template slot="option" slot-scope="{ option: user }">
                            <div>{{ user.name }}</div>
                            <span class="email-label t-text-xs t-opacity-50">{{ user.email }}</span>
                        </template>
                    </vue-multiselect>
                </div>

                <button-loading
                    @click="addAdmins"
                    :loading="addingAdmins"
                    class="btn btn-primary"
                    :disabled="!selectedAdmins || addingAdmins"
                >
                    Adicionar Administrador(es)
                </button-loading>
            </div>
        </div>

        <div class="app-card border t-border-orange-400 overflow-hidden">
            <app-table
                :headers="['ID', 'Nome', 'Email', 'Último Login', 'Ações']"
                :meta="admins.meta"
                :links="admins.links"
                @page="fetchAdmins"
                :loading="fetchingAdmins"
            >
                <tr v-for="(admin, index) of admins.data" :key="index">
                    <td>{{ admin.id }}</td>
                    <td>{{ admin.name }}</td>
                    <td>{{ admin.email }}</td>
                    <td>{{ admin.last_login_at | date }}</td>
                    <td>
                        <button-loading
                            v-if="admin.id !== $user.id && admin.id !== 1"
                            @click="removeAdmin(admin)"
                            class="btn btn-sm t-leading-tight btn-danger"
                            :loading="isRemovingAdmin(admin)"
                        >
                            <div v-if="!isRemovingAdmin(admin)">
                                <i class="fa fa-times"></i>
                            </div>
                        </button-loading>
                    </td>
                </tr>
            </app-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
    data: () => ({
        admins: {
            data: [],
        },
        selectedAdmins: null,
        userOptions: [],
        fetchingUsers: false,
        fetchingAdmins: false,
        addingAdmins: false,
        removingAdminIds: [],
    }),

    created() {
        this.fetchAdmins();
        this.fetchUsers();
    },

    methods: {
        async fetchAdmins(page = 1) {
            try {
                this.fetchingAdmins = true;
                const params = { page, search: this.search };
                const { data } = await axios.get(this.route('admin.admins.index'), { params });
                this.admins = data;
            } finally {
                this.fetchingAdmins = false;
            }
        },

        fetchUsers: _.debounce(async function(search) {
            try {
                this.fetchingUsers = true;
                const params = { search };
                const { data: users } = await axios.get(this.route('admin.admins.select'), { params });
                this.userOptions = users.data;
            } catch (error) {
                console.error('Oops', error);
            } finally {
                this.fetchingUsers = false;
            }
        }, 500),

        async addAdmins() {
            try {
                this.addingAdmins = true;
                const ids = this.selectedAdmins.map(a => a.id);
                const response = await axios.post(this.route('admin.admins.store'), { ids });
                this.$toasted.success('Administrador(es) adicionado(s) com sucesso');
                await this.fetchAdmins();
                this.selectedAdmins = [];
            } catch (error) {
                console.error('Oops', error);
            } finally {
                this.addingAdmins = false;
            }
        },

        async removeAdmin(admin) {
            try {
                this.removingAdminIds.push(admin.id);
                const response = await axios.delete(this.route('admin.admins.destroy', admin));
                this.$toasted.show('Administrador removido com sucesso');
                await this.fetchAdmins();
            } finally {
                this.removingAdminIds.splice(this.removingAdminIds.indexOf(admin.id), 1);
            }
        },

        isRemovingAdmin(admin) {
            return this.removingAdminIds.includes(admin.id);
        },
    }
}
</script>

<style lang="scss">
    .multiselect__element {
        .multiselect__option.multiselect__option--highlight {
            .email-label {
                @apply .t-text-white;
            }
        }
    }
</style>
