import axios from 'axios';
import Vue from 'vue';
import qs from 'qs';

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
}

axios.defaults.paramsSerializer = params => {
    return qs.stringify(params, { arrayFormat: 'brackets' });
}

axios.interceptors.response.use(
    success => success,
    error => {
        const { response } = error;
        const status = response.status;

        if (status === 403) {
            let message = response.data && response.data.message || 'Você não tem permissão para executar esta operação.';
            if ([
                'This action is unauthorized.'
            ].includes(message)) {
                message = 'Você não tem permissão para executar esta operação.';
            }
            Vue.toasted.global.forbidden(message);
        } else if (status > 401 && status !== 422) {
            Vue.toasted.global.error()
        } else if (status === 422) {
            Vue.toasted.error('Os dados informados são inválidos.');
        }

        return Promise.reject(error);
    }
)
