<template>
    <div class="container">
        <div
            v-for="invitation of invitations"
            :key="invitation.id"
            class="t-text-orange-800 t-bg-orange-100 t-p-3 t-mb-4 t-rounded t-shadow t-border-l-4 t-border-orange-600 t-flex t-items-center"
        >
            <p>
                Você foi convidado para a empresa <b class="t-font-bold">{{ invitation.company.name }}</b> inscrita no CNPJ:
                {{ invitation.company.cnpj | cnpj }}.
            </p>
            <div class="t-ml-auto">
                <a :href="route('invitations.reject', invitation)" class="btn t-px-1 t-py-0 t-rounded t-text-orange-600">Rejeitar</a>
                <a
                    :href="route('invitations.accept', invitation)"
                    class="btn hover:t-text-white hover:t-bg-green-600 t-px-1 t-py-0 t-rounded t-border t-border-green-600 t-text-green-600"
                    >Aceitar</a
                >
            </div>
        </div>

        <app-card>
            <app-table
                :loading="fetchingCompanies"
                :meta="companies.meta"
                :links="companies.links"
                :data="companies.data"
                :headers="['', 'ID', 'nome', 'cnpj', 'Responsável', 'Canal de Denúncia', 'Número de Membros', 'Ações']"
            >
                <tr v-for="company of companies.data" :key="company.id">
                    <td class="align-middle">
                        <div v-if="company.logo" class="t-w-10 t-h-10">
                            <img class="rounded t-object-contain" :src="company.logo.url" alt="" />
                        </div>
                    </td>
                    <td class="align-middle">{{ company.id }}</td>
                    <td class="align-middle t-whitespace-no-wrap">
                        <a class="text-primary" :href="route('settings.companies.show', company)">{{ company.name }}</a>
                    </td>
                    <td class="align-middle t-whitespace-no-wrap">
                        <a class="text-primary" :href="route('settings.companies.show', company)">{{ company.cnpj | cnpj }}</a>
                    </td>
                    <td class="align-middle t-whitespace-no-wrap">{{ company.owner.name }}</td>
                    <td class="align-middle t-whitespace-no-wrap">
                        <a class="text-primary" :href="route('complaints.portal', company.slug)" target="_blank">
                            {{ route('complaints.portal', company.slug) }}
                        </a>
                    </td>
                    <td class="align-middle">{{ company.users.length }}</td>
                    <td class="align-middle t-whitespace-no-wrap">
                        <a :href="route('settings.companies.show', company)" class="btn btn-sm btn-default">
                            <i class="fa fa-eye"></i>
                        </a>

                        <button
                            v-if="$user.id !== company.owner.id"
                            @click="confirmLeaving(company)"
                            class="btn btn-sm btn-default t-text-red-500"
                        >
                            <i class="fa fa-sign-out-alt"></i>
                        </button>
                    </td>
                </tr>
            </app-table>
        </app-card>

        <modal ref="confirm" hide-close>
            <p class="lead">
                Tem certeza que deseja <b class="t-font-bold">deixar</b> a empresa
                <b class="t-font-bold" v-if="companyToLeave">{{ companyToLeave.name }}</b
                >?
            </p>

            <div class="t-flex t-justify-end" slot="footer">
                <button class="btn mr-2 t-uppercase" @click="$refs.confirm.close()">Não</button>
                <button-loading @click="leave" :loading="leavingCompany" class="btn btn-danger t-uppercase">Sim</button-loading>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        props: {
            initialData: { required: true, type: Object },
            invitations: { default: () => [], type: Array }
        },

        data() {
            return {
                companies: this.initialData,
                companyToLeave: null,
                leavingCompany: false,
                fetchingCompanies: false
            };
        },

        methods: {
            confirmLeaving(company) {
                this.companyToLeave = company;
                this.$refs.confirm.open();
            },

            async fetchCompanies(page = 1) {
                const params = { page };
                this.fetchingCompanies = true;

                try {
                    const { data: companies } = await this.$http.get(this.route('settings.companies.index'), { params });
                    this.companies = companies;
                } finally {
                    this.fetchingCompanies = false;
                }
            },

            async leave() {
                try {
                    this.leavingCompany = true;
                    await this.$http.delete(this.route('settings.companies.leave', this.companyToLeave));
                    this.$toasted.success(`Você deixou a empresa ${this.companyToLeave.name}`);
                    await this.fetchCompanies();
                    this.$refs.confirm.close();
                } finally {
                    this.leavingCompany = false;
                }
            }
        }
    };
</script>
