<template>
    <div class="form-group">
        <label :class="[{
            'text-danger': form.errors.has(name)
        }]" v-if="label">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>

        <select
            :class="['form-control', {
                'is-invalid': form.errors.has(name)
            }]"
            @input="$emit('input', $event.target.value)"
            v-bind="$props">
            <option v-if="placeholder || allowNull" :value="null" :disabled="!allowNull" selected>{{ placeholder }}</option>
            <option v-if="allowEmpty" :value="''"></option>
            <option v-for="(item, index) of sortedOptions" :key="index" :value="itemValue(item)">
                {{ itemLabel(item) }}
            </option>
        </select>

        <p class="invalid-feedback d-block" v-if="form.errors.has(name)">
            {{ form.errors.get(name) }}
        </p>
    </div>
</template>

<script>
    import { Form } from "@/utils";
    export default {
        props: {
            label: { default: null },
            value: { default: null },
            name: { default: null },
            placeholder: { default: null },
            form: { default: () => new Form(), type: Form },
            required: { default: false, type: Boolean },
            trackBy: { default: "id", type: String },
            labelBy: { default: "name", type: String },
            options: { default: () => [], type: Array },
            sorted: { default: false, type: Boolean },
            allowNull: { default: false, type: Boolean },
            allowEmpty: { default: false, type: Boolean },
        },

        computed: {
            sortedOptions() {
                if (!this.sorted) {
                    return this.options;
                }

                if (this.optionsAreObjects) {
                    return this.options.sort((a, b) =>
                        a[this.labelBy] < b[this.labelBy] ? -1 : 1
                    );
                }

                return this.options.sort((a, b) => (a < b ? -1 : 1));
            },
            optionsAreObjects() {
                const option = this.options.length && this.options[0];
                return typeof option === "object";
            }
        },

        watch: {
            value() {
                this.form.errors.clear(this.name);
            }
        },

        methods: {
            itemValue(item) {
                return this.isObject(item) ? item[this.trackBy] : item;
            },

            itemLabel(item) {
                return this.isObject(item) ? item[this.labelBy] : item;
            },

            isObject(item) {
                return typeof item === "object";
            }
        }
    };
</script>
