<template>
    <div class="t-relative">
        <div class="table-responsive" :class="{'table-loading': loading}">
            <table class="table mb-0" :class="tableClass">
                <thead>
                    <slot name="thead">
                        <th v-for="header of headers" :key="header" class="t-border-t-0 t-uppercase t-whitespace-no-wrap">{{ header }}</th>
                    </slot>
                </thead>

                <tbody>
                    <slot></slot>
                    <tr v-if="data && !data.length && !loading">
                        <td :colspan="headers.length || 1">Não há dados para mostrar.</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="meta && meta.last_page > 1" class="p-3 w-100 t-flex-grow">
            <pagination @page="$emit('page', $event)" :meta="meta" :links="links"
                class="flex-row-reverse justify-content-between"
            />
        </div>

        <transition name="fade">
            <div v-if="loading" class="loading t-flex t-items-center t-justify-center">
                <div class="t-flex t-items-center t-text-gray-600">
                    <div>
                        <i class="fa fa-2x fa-spinner fa-spin"></i>
                    </div>

                    <span class="t-text-xl ml-2">Carregando</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        headers: { default: null, type: Array },
        meta: { default: null, type: Object },
        links: { default: null, type: Object },
        data: { default: null, type: Array },
        loading: { default: false, type: Boolean },
        tableClass: { default: 'table-striped' },
    }
}
</script>

<style lang="scss" scoped>
    .table-responsive {
        position: relative;
        &.table-loading {
            min-height: 200px;
        }
    }
    .loading {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
