<template>
    <div class="t-min-h-screen t-bg-white t-flex">
        <div class="t-flex-1 t-flex t-flex-col t-justify-center t-py-12 t-px-4 lg:t-flex-none xl:t-px-24 lg:t-px-20">
            <div class="t-mx-auto t-w-full t-max-w-xl">
                <div class="mx-auto" style="width: 400px">
                    <div class="t-flex t-justify-center t-flex-col t-items-start">
                        <img v-if="company.logo" :src="company.logo.url" class="t-w-20 t-rounded t-mb-4" alt="" />

                        <page-title>
                            Complete seu Cadastro
                            <div class="t-text-base t-normal-case t-font-normal t-text-gray-500">
                                {{ company.name }}
                            </div>
                        </page-title>
                    </div>

                    <input-text v-model="form.name" label="Nome" name="name" :form="form" required></input-text>
                    <input-text v-model="form.email" label="E-mail" name="email" :form="form" required></input-text>
                    <input-text
                        :form="form"
                        name="phone"
                        required
                        v-model="form.phone"
                        label="Telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                    <input-text v-model="form.cpf" label="CPF" name="cpf" :form="form" required v-mask="'###.###.###-##'"></input-text>
                    <input-text v-model="form.password" label="Senha" type="password" name="password" :form="form" required></input-text>
                    <input-text
                        v-model="form.password_confirmation"
                        label="Confirmação de Senha"
                        type="password"
                        name="password_confirmation"
                        :form="form"
                        required
                    ></input-text>

                    <button-loading
                        @click="submit"
                        :loading="form.submitting"
                        class="btn t-mt-4 btn-primary t-w-full t-text-xl t-rounded-lg t-shadow-lg"
                    >
                        Cadastrar
                    </button-loading>
                </div>
            </div>
        </div>

        <div class="t-hidden lg:t-block t-relative t-w-0 t-flex-1">
            <img
                class="t-absolute t-inset-0 t-h-full t-w-full t-object-cover"
                src="https://images.pexels.com/photos/459654/pexels-photo-459654.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import { Form } from '@/utils';
export default {
    props: {
        invitation: { required: true, type: Object }
    },

    data() {
        return {
            form: new Form({
                email: this.invitation.email
            })
        };
    },

    computed: {
        company() {
            return this.invitation.company;
        }
    },

    methods: {
        async submit() {
            await this.form.post(this.route('invitations.register.store', this.invitation));
            window.location.href = this.route('home');
        }
    }
};
</script>
