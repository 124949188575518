import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
    duration: 5000,
    keepOnHover: true,
    iconPack: 'fontawesome'
});
Vue.toasted.register('success', 'Operação realizada com sucesso', {
    type: 'success',
    duration: 5000,
    singleton: true
});
Vue.toasted.register('error', 'Oops! Ocorreu um erro desconhecido mas nós já estamos tratando.', {
    type: 'error',
    duration: 5000,
    singleton: true
});
Vue.toasted.register('forbidden', message => message, {
    type: 'error',
    duration: 5000,
    singleton: true
});
