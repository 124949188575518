<template>
    <div class="container">
        <page-title class="mb-3">Análise de Denúncia</page-title>

        <div
            v-if="!complaint.analyses.length"
            class="t-p-4 t-rounded mb-3 lead t-border-l-4 t-border-blue-500 t-bg-blue-100 t-text-blue-500"
        >
            Nenhum parecer foi enviado para esta denúncia ainda.
        </div>

        <app-card class="mb-3">
            <div class="card-body lead">
                <div class="row">
                    <div class="col-auto t-opacity-50">
                        <p>Protocolo:</p>
                        <p>Data:</p>
                        <p>Status:</p>
                        <p>Área / Departamento:</p>
                        <p>Tipo de Solicitação:</p>
                        <p>Anexo:</p>
                    </div>

                    <div class="col">
                        <div class="t-float-right">
                            <a
                                :href="viewAsReporterUrl"
                                class="btn btn-light"
                            >
                                <i class="fa fa-eye mr-2"></i>
                                Ver Como Relator
                            </a>
                        </div>
                        <p>{{ complaint.protocol }}</p>
                        <p>{{ complaint.created_at | date('LLLL') }}</p>
                        <p>{{ complaint.status | trans('complaint_statuses') }}</p>
                        <p>{{ complaint.section | trans('complaint_sections') }}</p>
                        <p>{{ complaint.type | trans('complaint_types') }}</p>
                        <p>
                            <a
                                v-if="fileUrl"
                                class="text-primary"
                                :href="fileUrl"
                                target="_blank"
                            >
                                {{ complaint.file && complaint.file.original_name }}
                            </a>

                            <div
                                class="t-opacity-50"
                                v-if="!fileUrl"
                            >
                                <span class="t-italic">Nenhum anexo</span>
                            </div>
                        </p>
                    </div>
                </div>

                <p class="t-opacity-50">Descrição da Denúncia:</p>
                <p class="t-p-4 t-rounded-lg t-bg-gray-200">{{ complaint.description }}</p>
            </div>
        </app-card>

        <app-card>
            <div class="card-body">
                <input-textarea
                    v-model="form.description"
                    name="description"
                    label="Parecer"
                    :form="form"
                />
                <div class="t-flex t-items-start t-justify-end">
                    <div class="form-group mb-0 mr-auto">
                        <div class="custom-control custom-checkbox mb-0">
                            <input
                                type="checkbox"
                                v-model="form.public"
                                class="custom-control-input"
                                id="public"
                            >
                            <label
                                class="custom-control-label"
                                for="public"
                            >
                                <b>ATENÇÃO:</b> Ao marcar esta opção o parecer será <b>público</b> ao pesquisador pelo protocolo.
                            </label>
                        </div>
                    </div>

                    <input-select
                        class="mb-0 mr-3"
                        :form="form"
                        name="status"
                        placeholder="Status"
                        :options="$constants.complaint_statuses"
                        v-model="form.status"
                    />

                    <input-select
                        class="mb-0 mr-3"
                        :form="form"
                        name="section"
                        placeholder="Área / Departamento"
                        :options="$constants.complaint_sections"
                        v-model="form.section"
                    />

                    <button-loading
                        @click="save"
                        :loading="form.submitting"
                        class="btn btn-primary"
                    >
                        Enviar Parecer
                    </button-loading>
                </div>
            </div>
        </app-card>

        <app-card
            class="mt-5"
            v-if="complaint.analyses.length"
        >
            <div class="card-body">
                <div
                    v-for="analysis of complaint.analyses"
                    :key="analysis.id"
                    class="t-p-4 t-mb-4 last:t-mb-0 t-rounded-lg t-bg-gray-100 t-flex t-items-start"
                >

                    <div
                        class="mr-3 t-rounded-full t-overflow-hidden"
                        style="min-width: 45px"
                    >
                        <avatar
                            :username="analysis.user.name"
                            :size="45"
                        />
                    </div>

                    <div class="t-flex-1">
                        <div class="t-text-gray-600">
                            <span class="t-font-bold t-uppercase">{{ analysis.user.name }}</span>
                            <span class="ml-5">
                                Status:
                                <span class="t-font-bold t-text-sm">{{ analysis.status | trans('complaint_statuses') }}</span>

                                <span class="ml-3">
                                    Área / Departamento:
                                    <span class="t-font-bold t-text-sm">{{ analysis.section | trans('complaint_sections') }}</span>
                                </span>

                                <span class="float-right">
                                    Visibilidade:
                                    <span
                                        class="t-font-bold t-text-sm"
                                        :class="analysis.public ? 'text-success' : 't-text-orange-600'"
                                    >{{ analysis.public ? 'Pública' : 'Privada' }}</span>
                                </span>

                            </span>

                            <div class="t-text-sm">{{ analysis.created_at | date('LLLL') }}</div>
                        </div>
                        <p class="lead">
                            <span class="t-font-bold">Parecer: </span>
                            {{ analysis.description }}
                        </p>
                    </div>
                </div>
            </div>
        </app-card>
    </div>
</template>

<script>
import { Form } from '@/utils';

export default {
    props: {
        complaint: { required: true, type: Object },
    },

    data() {
        return {
            form: new Form({
                section: this.complaint.section,
                status: this.complaint.status,
            }),
        };
    },

    computed: {
        fileUrl() {
            const { file } = this.complaint;
            if (!file) return null;
            return file.type.includes('image') ? file.url : file.download_url;
        },

        viewAsReporterUrl() {
            return this.route('complaints.view', {
                company: this.complaint.company.slug,
                protocol: this.complaint.protocol,
            });
        },
    },

    methods: {
        async save() {
            await this.form.post(this.route('complaints.post-analysis', this.complaint));
            window.location.reload();
        },
    },
};
</script>
