<template>
    <div class="t-bg-white t-shadow t-rounded-lg t-overflow-hidden">
        <div class="t-px-6 t-py-5 t-border-b t-border-gray-200">
            <h3 class="t-text-lg t-leading-6 t-fond-medium t-text-gray-900">
                Informações da Empresa
            </h3>
            <p class="t-mt-1 t-max-w-2xl t-text-sm t-leading-5 t-text-gray-500">
                Informações e dados importantes da empresa.
            </p>
        </div>

        <div class="t-p-0">
            <div class="t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">
                    Logo
                </dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">
                    <img class="t-h-20 rounded" :src="company.logo.url" v-if="company.logo" alt="" />
                </dt>
            </div>

            <div class="t-grid t-border-t t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">
                    Razão Social
                </dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">{{ company.name }}</dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">
                    CNPJ
                </dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">{{ company.cnpj | cnpj }}</dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">
                    Tipo
                </dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">
                    {{ company.cnpj_head ? 'Filial' : 'Matriz' }}
                    <span v-if="company.cnpj_head">de {{ company.cnpj_head | cnpj }}</span>
                </dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">
                    Canal de Denúncia
                </dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">
                    <a class="text-primary" target="_blank" :href="route('complaints.portal', company.slug).url()">
                        {{ route('complaints.portal', company.slug).url() }}
                    </a>
                </dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">Responsável</dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">
                    {{ company.owner.name }}
                    (<a class="text-primary" :href="'mailto:' + company.owner.email">{{ company.owner.email }}</a
                    >)
                </dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">Telefone Primário</dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">{{ company.phone }}</dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">Telefone Secundário</dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">{{ company.phone_secondary }}</dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">Endereço Completo</dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">
                    {{ company.address_line }}
                    {{ company.address_number }}
                    {{ company.address_city }}
                    {{ company.address_state }}
                    {{ company.address_zipcode }}
                </dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">Inscrição Estadual</dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">
                    {{ company.state_subscription }}
                </dt>
            </div>

            <div class="t-border-t t-grid t-grid-cols-3 t-gap-4 t-px-6 t-py-5">
                <dd class="t-text-sm t-leading-5 t-font-medium t-text-gray-600">Inscrição Municipal</dd>

                <dt class="t-leading-5 t-col-span-2 t-text-gray-900 t-font-normal">
                    {{ company.city_subscription }}
                </dt>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            company: { required: true, type: Object }
        }
    };
</script>
