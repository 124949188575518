<template>
    <div class="container mt-5">
        <page-title class="t-text-center mb-4">
            {{__('Complaint Status')}}
            <div class="t-text-lg t-opacity-50">{{ company.name }}</div>
        </page-title>

        <p class="lead t-text-3xl t-text-gray-600">
            {{ __('Protocol') }}: <b class="t-font-bold">{{ complaint.protocol }}</b>
        </p>
        <p class="lead">{{ __('Opening Date') }}: {{ complaint.created_at | date }}</p>
        <p class="lead mb-5">{{ __('Consult Date') }}: {{ new Date() | date }}</p>

        <app-card class="mb-4">
            <app-table
                table-class
                :headers="[__('Date'), __('Area'), __('Status'), __('Comments')]"
            >
                <tr
                    v-for="(analysis, index) of complaint.analyses"
                    :key="analysis.id"
                    :class="{ 't-bg-gray-100': index === 0, 't-opacity-50': index !== 0 }"
                >
                    <td
                        class="align-middle"
                        style="min-width: 180px"
                    >
                        {{ analysis.created_at | date }}
                        <span
                            class="t-text-green-500"
                            v-if="index === 0"
                        >{{ __('Most recent') }}</span>
                    </td>
                    <td class="align-middle t-whitespace-no-wrap">{{ analysis.section | trans('complaint_sections') }}</td>
                    <td class="align-middle t-whitespace-no-wrap">{{ analysis.status | trans('complaint_statuses') }}</td>
                    <td class="align-middle">{{ analysis.description }}</td>
                </tr>

                <tr>
                    <td
                        class="align-middle"
                        style="min-width: 180px"
                    >
                        {{ complaint.created_at | date }}
                        <span class="t-text-gray-500">{{ __("Complaint's Opening") }}</span>
                    </td>
                    <td class="align-middle">{{ firstComplaint.section | trans('complaint_sections') }}</td>
                    <td class="align-middle t-whitespace-no-wrap">{{ 'opened' | trans('complaint_statuses') }}</td>
                    <td class="align-middle">
                        <div class="t-text-gray-500 t-italic"></div>
                    </td>
                </tr>
            </app-table>
        </app-card>

        <div class="text-center mb-5">
            <a
                :href="route('complaints.consult', company.slug)"
                class="btn t-bg-gray-200 hover:t-bg-gray-300 t-text-2xl"
            >{{ __('New Consult') }}</a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    props: {
        company: { required: true, type: Object },
        complaint: { required: true, type: Object },
    },

    computed: {
        firstComplaint() {
            const audit = this.complaint.audits[0];
            return (audit && audit.new_values) || {};
        },
    },
};
</script>
