<template>
    <div class="container">
        <page-title>Denúncias</page-title>

        <app-card class="mb-3">
            <div class="card-body">
                <h5 class="card-title t-text-gray-500 t-font-bold">Filtros</h5>

                <div class="form-row mb-3">
                    <input-search v-model="filter.protocol" @input="fetchComplaints()" placeholder="Protocolo" class="col mb-0" />

                    <input-select
                        @input="fetchComplaints()"
                        class="col mb-0"
                        v-model="filter.status"
                        :options="$constants.complaint_statuses"
                        placeholder="Selecione um Status"
                        allow-null
                    />

                    <input-select
                        @input="fetchComplaints()"
                        class="col mb-0"
                        v-model="filter.type"
                        :options="$constants.complaint_types"
                        placeholder="Selecione o Tipo de Solicitação"
                        allow-null
                    />

                    <input-select
                        @input="fetchComplaints()"
                        class="col mb-0"
                        v-model="filter.section"
                        :options="$constants.complaint_sections"
                        placeholder="Selecione a Área/Departamento"
                        allow-null
                    />
                </div>

                <div class="form-row">
                    <input-date-picker
                        class="col"
                        @input="fetchComplaints()"
                        v-model="filter.opened_date"
                        label="Data de Abertura"
                        range
                    />

                    <input-date-picker
                        class="col"
                        @input="fetchComplaints()"
                        v-model="filter.concluded_date"
                        label="Data de Encerramento/Conclusão"
                        range
                    />

                    <input-date-picker
                        class="col"
                        @input="fetchComplaints()"
                        v-model="filter.archived_date"
                        label="Data de Arquivamento"
                        range
                    />
                </div>
            </div>
        </app-card>

        <app-card>
            <app-table
                :headers="['protocolo', 'Data', 'Relator', 'Email', 'Tipo de Solicitação', 'Área/Departamento', 'Situação', 'Análises']"
                :links="complaints.links"
                :meta="complaints.meta"
                :data="complaints.data"
                :loading="fetchingComplaints"
                @page="fetchComplaints"
            >
                <tr v-for="complaint of complaints.data" :key="complaint.id">
                    <td class="t-align-middle">
                        <a class="text-primary t-underline" :href="route('complaints.edit', complaint)">{{ complaint.protocol }}</a>
                    </td>
                    <td class="t-align-middle">{{ complaint.created_at | date('L') }}</td>
                    <td class="t-align-middle">{{ complaint.reporter_name }}</td>
                    <td class="t-align-middle">{{ complaint.reporter_email }}</td>
                    <td class="t-align-middle">{{ complaint.type | trans('complaint_types') }}</td>
                    <td class="t-align-middle">{{ complaint.section | trans('complaint_sections') }}</td>
                    <td class="t-align-middle">{{ complaint.status | trans('complaint_statuses') }}</td>
                    <td class="t-align-middle">{{ complaint.analyses_count }}</td>
                </tr>
            </app-table>
        </app-card>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        initialData: { required: true, type: Object }
    },

    data() {
        return {
            complaints: this.initialData,
            fetchingComplaints: false,
            filter: { }
        };
    },

    methods: {
        async fetchComplaints(page = 1) {
            try {
                this.fetchingComplaints = true;
                const params = { page, filter: this.removeNullValues(this.filter) };

                const { data: complaints } = await axios.get(this.route('complaints.index'), { params });
                this.complaints = complaints;
            } finally {
                this.fetchingComplaints = false;
            }
        },

        removeNullValues(target) {
            const object = target;
            Object.keys(object).forEach(key => {
                const value = object[key];
                if (value === null || value === '') {
                    delete object[key];
                }
            });

            return object;
        }
    }
};
</script>
