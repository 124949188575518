<template>
    <button-loading
        @click="onClick"
        v-bind="$props"
        class="btn btn-primary"
        :style="{backgroundColor: color, borderColor: color, color: $invertColor(color)}"
    >
        <slot></slot>
    </button-loading>
</template>

<script>
import Vue from 'vue';

export default {
    props: {
        color: { default: () => Vue.prototype.$primaryColor },
        loading: { default: false },
        href: { default: null },
    },

    methods: {
        onClick(event) {
            if (this.href) {
                window.location.href = this.href;
                return;
            }

            this.$emit('click', event);
        },
    },
};
</script>
