<template>
    <div
        class="mx-auto"
        style="max-width: 500px"
    >
        <h3 class="t-text-2xl t-text-green-500 mb-4 t-text-center t-uppercase mt-5">
            {{ __('Report Submitted') }}
            <i class="fa fa-check-circle"></i>
        </h3>

        <div class="mx-auto mb-3 t-bg-gray-200 t-p-8 t-rounded-lg">
            <p class="t-text-xl mb-0">{{ __('Protocol') }}:</p>
            <div class="t-text-4xl">{{ complaint.protocol }}</div>
        </div>

        <p class="lead mb-5">
            {{ __('Write down the generated protocol to see the progress of your report. If you have filled in a contact email, this protocol will be sent to the email provided.') }}
        </p>

        <primary-button
            @click="$emit('newComplaint')"
            :color="complaint.company && complaint.company.primary_color"
            class="t-block t-mx-auto t-rounded-lg t-px-8 t-py-4 t-text-xl t-uppercase"
        >
            {{__('Open New Complaint')}}
        </primary-button>
    </div>
</template>

<script>
import { Form } from '@/utils';

export default {
    props: {
        complaint: { required: true, type: Object },
    },
};
</script>
