<template>
    <span class="t-px-2 t-capitalize t-font-semibold t-rounded-full" :class="[bgColor, textColor]">
        <slot></slot>
    </span>
</template>

<script>
    export default {
        props: {
            color: { default: 'gray' }
        },

        computed: {
            bgColor() {
                return `t-bg-${this.color}-100`;
            },

            textColor() {
                return `t-text-${this.color}-700`;
            }
        }
    };
</script>
